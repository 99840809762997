<template>
  <main class="page-overflow ma-4">


    <!-- page header -->
    <div class="page-header-3 mx-2">

      <!-- page header: filters -->
      <div class="page-header-filters">
        <!-- go to individual report -->
        <h6 class="main--text" style="cursor:pointer" @click="$router.push('/employee-time-report')">
          <v-icon :size="10">mdi-arrow-left</v-icon>
          {{ translations.goTo }} {{ translations.individualReport }}
        </h6>

        <!-- empty filter -->
        <div></div>

        <!-- employee filter -->
        <v-autocomplete :items="employeeList" item-text="name" item-value="id" :placeholder="translations.filterForEmployee"
                        :search-input.sync="employeeSearch" :loading="$apollo.queries.employees.loading"
                        @change="val => (filters.employeeId = val)" @click:clear="filters.employeeId = null"
                        clearable dense hide-details autocomplete="off">
          <template v-slot:append-item>
            <div style="background: white;" class="d-flex justify-center" v-intersect="onIntersect" v-if="hasNextEmployees && !$apollo.queries.employees.loading">
              <v-progress-circular indeterminate color="main"></v-progress-circular>
            </div>
          </template>
          <template v-slot:no-data>
            <v-list-item>
              {{ translations.noEmployeesFoundWThatName }}
            </v-list-item>
          </template>
        </v-autocomplete>
      </div>

      <!-- page header: title -->
      <div class="all-center">
        <time-report-date-header :displayType="displayType" :displayItem="displayItem"
                                 :today="today" :todayDate="todayDateNum" :todayMonth="todayMonthNum" :todayYear="todayYearNum"
                                 :maxDateWeekly="maxDateWeekly" :headersHaveMaxDate="headersHaveMaxDate"
                                 @previous="previousHandler" @next="nextHandler" @set-weekly="setWeeklyDisplay" @set-monthly="setMonthlyDisplay" @set-custom="setCustomDisplay"/>
      </div>

      <!-- page header: right -->
      <div class="all-center justify-space-around">
        <div class="all-center" style="width: 20%">
        </div>

        <div class="all-center" style="width: 60%">
          <!-- report dialog -->
          <report-dialog :reports="exportReports"></report-dialog>
        </div>
      </div>
    </div>


    <!-- main content -->
    <v-card class="ma-1">
      <v-data-table :headers="newHeaders" :items="newItems" :items-per-page="-1"
                    class="page-table time-table" fixed-header :footer-props="tableFooter"
                    disable-sort :loading="$apollo.queries.dailyReports.loading">

        <template v-slot:no-data>
          <div class="tableSingleSlot">
            {{ translations.noDataFound }}
          </div>
        </template>

        <template v-slot:loading>
          <div class="tableSingleSlot">
            {{ translations.loading }}
          </div>
        </template>

        <template v-for="(header, index) in newHeaders" v-slot:[`item.${header.value}`]="{ item }">
          <div v-if="header.value === 'employee'" @click="redirectEmployeeReport(item)" :key="`emp-${index}`" class="fixed-employee cursor-pointer" style="min-width: 150px; ">
            <span>{{ item.employee }}</span>
          </div>

          <div v-else-if="header.value === 'totalOrdinaryHours'" :key="`emp-total-${index}`" style="text-align: center; min-width: 80px">
            <span>{{ item.totalOrdinaryHours }}</span>
          </div>

          <div v-else-if="header.value === 'totalExtraOrdinaryHours'" :key="`emp-total-extra-${index}`" style="text-align: center; min-width: 100px">
            <span>{{ item.totalExtraOrdinaryHours }}</span>
          </div>

          <div v-else-if="header.value.includes('totalAllowance')" :key="`emp-total-allowance-${index}`" style="text-align: center; min-width: 100px">
            <span>{{ item[header.value] ? item[header.value] : '-' }}</span>
          </div>

          <div v-else-if="header.value === 'usedVan'" :key="`used-van-${index}`" style="text-align: center; min-width: 80px">
            <v-chip v-if="item[header.value]" color="#E7F5E9" small label>
              <div class="d-flex align-center">
                <v-icon x-small class="mr-1" color="#2A6A2D">
                  mdi-check
                </v-icon>
                <span style="font-size: .875rem; color: #2A6A2D">{{ translations.yes }}</span>
              </div>
            </v-chip>

            <v-chip v-else color="#FFEAEC" small label>
              <div class="d-flex align-center">
                <v-icon x-small class="mr-1" color="#D60011">
                  mdi-close
                </v-icon>
                <span style="font-size: .875rem; color: #D60011">
                {{ translations.no }}
              </span>
              </div>
            </v-chip>
          </div>

          <time-report-cell v-else :key="`date-${index}`" :dailyReport="item[header.value] || {}" :isToday="header.value === today"></time-report-cell>
        </template>
      </v-data-table>
    </v-card>
  </main>
</template>


<script>
import moment from 'moment'
import { uniqBy } from 'lodash'
import { months, translations } from '@/utils/common'
import { MomentMixin } from '@/utils/mixins/MomentMixin.js'
import { getExportReports } from '@/utils/api/config'

import employeesQuery from '@/graphql/Employees.gql'
import dailyReportsQuery from '@/graphql/DailyReports.gql'

import TimeReportCell from '@/components/TimeReportCell.vue'
import TimeReportDateHeader from '@/components/TimeReportDateHeader.vue'
import ReportDialog from '@/components/Report/ReportDialog.vue'

export default {
  components: {
    TimeReportCell,
    TimeReportDateHeader,
    ReportDialog
  },

  apollo: {
    employees: {
      query: employeesQuery,
      variables() {
        return {
          page: 1,
          pageSize: this.employeeQueryVars.pageSize,
          sort: 'name',
          order: 'asc',
          name: this.employeeSearch,
          showDisabled: true
        }
      }
    },

    dailyReports: {
      query: dailyReportsQuery,
      variables() {
        if (this.filters.employeeId)
          return {
            dateStrFrom: this.startDate ? this.formatDate(this.startDate) : null,
            dateStrTo: this.endDate ? this.formatDate(this.endDate) : null,
            employee: { id: this.filters.employeeId }
          }

        return {
          dateStrFrom: this.startDate ? this.formatDate(this.startDate) : null,
          dateStrTo: this.endDate ? this.formatDate(this.endDate) : null
        }
      }
    }
  },

  data() {
    return {
      translations: translations,

      dateHeaders: [],
      tableFooter: {
        'items-per-page-text': '',
        'items-per-page-options': [-1, 10, 25, 50, 100],
        'items-per-page-all-text': 'Tutti'
      },

      parentMenu: false,
      employeeSearch: null,
      hasNextEmployees: false,
      employeeQueryVars: {
        page: 1,
        pageSize: 10,
        sort: 'name',
        order: 'asc'
      },
      filters: {
        employeeId: null
      },
      employeeList: [],
      todayDateNum: null,
      todayMonthNum: null,
      todayYearNum: null,
      displayingMonth: {
        text: null,
        value: null
      },
      displayingWeek: {},
      displayingCustom: {},
      currentMonth: null,
      currentYear: null,
      displayType: 'monthly',
      startDate: null,
      endDate: null
    }
  },

  mixins: [MomentMixin],

  async created() {
    const d = new Date()
    const day = d.getDate()
    const month = d.getMonth()
    const year = d.getFullYear()
    this.todayDateNum = day
    this.todayMonthNum = month
    this.todayYearNum = year
    this.currentMonth = month
    this.displayingMonth.text = `${this.months[month]} ${year}`
    this.displayingMonth.value = month
    this.currentYear = year
    this.dateHeaders = await this.getMonthlyDates(month, year)
  },

  methods: {
    async fetchMoreEmployees() {
      this.employeeQueryVars.page++

      const res = await this.$apollo.query({
        query: employeesQuery,
        variables: { ...this.employeeQueryVars },
        fetchPolicy: 'network-only'
      })

      this.hasNextEmployees = res.data.employees.hasNext
      this.employeeList.push(...res.data.employees.content)
    },

    async onIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) {
        await this.fetchMoreEmployees()
      }
    },
    // <================ Monthly Display =============>
    getMonthlyDates(month, year) {
      let date = new Date(year, month, 1)
      let days = []

      while (date.getMonth() === month) {
        const dateObj = {
          text: this.momentDate(date, 'DD/MM/YYYY'),
          value: this.formatDate(date)
        }
        days.push(dateObj)
        date.setDate(date.getDate() + 1)
      }

      this.startDate = this.formatDate(days[0].value)
      this.endDate = this.formatDate(days[days.length - 1].value)
      return days
    },

    async setMonthlyDisplay() {
      this.displayType = 'monthly'
      this.displayingMonth.text = `${this.months[this.todayMonthNum]} ${this.currentYear} `
      this.displayingMonth.value = this.todayMonthNum
      this.dateHeaders = await this.getMonthlyDates(this.todayMonthNum, this.todayYearNum)
    },

    // <================ Monthly Display End =============>

    // <================ Weekly Display Start =============>
    setWeeklyHeaders(startDay, endDay, month, year, totalDays) {
      let date = new Date(year, month, startDay)
      let iterations = 0
      let days = []

      this.startDate = this.formatDate(date)
      while (iterations < totalDays) {
        const dateObj = {
          text: this.momentDate(date, 'DD/MM/YYYY'),
          value: this.formatDate(date)
        }
        days.push(dateObj)
        date.setDate(date.getDate() + 1)
        iterations++
      }

      return days
    },

    async setWeeklyDisplay(date) {
      const startDateText = this.momentDate(date, 'DD/MM/YYYY')

      const dateArr = date.split('-')
      const startDayNum = dateArr[2]
      const startMonth = dateArr[1] - 1
      const year = dateArr[0]
      //used for the headers
      const endDateText = moment(date).add(6, 'days')
      const endDayNum = endDateText.format('DD')

      this.startDate = this.formatDate(date)

      //used for display
      const endDateUnFormatted = endDateText.format('YYYY-MM-DD')
      this.endDate = endDateUnFormatted
      let endDateFormatted = this.momentDate(endDateUnFormatted, 'DD/MM/YYYY')

      this.dateHeaders = await this.setWeeklyHeaders(startDayNum, endDayNum, startMonth, year, 7)

      endDateFormatted = this.momentDate(this.dateHeaders[this.dateHeaders.length - 1].value, 'DD/MM/YYYY')

      this.displayType = 'weekly'
      this.displayingWeek = {
        text: `${startDateText} - ${endDateFormatted}`,
        value: {
          startDate: this.startDate,
          endDate: this.endDate
        }
      }
    },
    //<================ Weekly Display End=============>

    //<=====================Custom Display Start =====================>
    setCustomHeaders(startDate, endDate) {
      let date = new Date(startDate)
      const lastDate = new Date(endDate)
      let days = []

      while (date <= lastDate) {
        const dateObj = {
          text: this.momentDate(date, 'DD/MM/YYYY'),
          value: this.formatDate(date)
        }
        days.push(dateObj)
        date.setDate(date.getDate() + 1)
      }

      return days
    },

    async setCustomDisplay(dates) {
      const { from, to } = dates
      const startDateText = this.momentDate(from, 'DD/MM/YYYY')

      this.startDate = from
      this.endDate = to
      this.dateHeaders = await this.setCustomHeaders(from, to)

      let endDateFormatted = this.momentDate(this.dateHeaders[this.dateHeaders.length - 1].value, 'DD/MM/YYYY')

      this.displayType = 'custom'
      this.displayingCustom = {
        text: `${startDateText} - ${endDateFormatted}`,
        value: {
          startDate: this.startDate,
          endDate: this.endDate
        }
      }
    },
    //<======================= Custom Display End ====================>

    async previousHandler() {
      //If displaying monthly results
      if (this.displayType === 'monthly') {
        if (this.currentMonth === 0) {
          this.currentMonth = 11
          this.currentYear--
        } else {
          this.currentMonth--
        }
        this.dateHeaders = await this.getMonthlyDates(this.currentMonth, this.currentYear)
        this.displayingMonth.text = `${this.months[this.currentMonth]} ${this.currentYear} `
        this.displayingMonth.value = this.currentMonth
      } else if (this.displayType === 'weekly') {
        const dateToChange = moment(this.displayingWeek.value.startDate.slice(0, 10))
          .subtract(6, 'days')
          .format('YYYY-MM-DD')

        this.setWeeklyDisplay(dateToChange)
      }
    },

    async nextHandler() {
      //If displaying monthly results
      if (this.displayType === 'monthly') {
        if (this.currentMonth === 11) {
          this.currentMonth = 0
          this.currentYear++
        } else {
          this.currentMonth++
        }
        this.dateHeaders = await this.getMonthlyDates(this.currentMonth, this.currentYear)
        this.displayingMonth.text = `${this.months[this.currentMonth]} ${this.currentYear} `
        this.displayingMonth.value = this.currentMonth
      } else if (this.displayType === 'weekly') {
        const dateToChange = this.displayingWeek.value.endDate.slice(0, 10)
        this.setWeeklyDisplay(dateToChange)
      }
    },

    redirectEmployeeReport(item) {
      this.$router.push({
        name: 'EmployeeTimeReport',
        query: {
          employeeId: item.employeeId,
          displayType: this.displayType,
          currentMonth: this.currentMonth,
          currentYear: this.currentYear,
          startDate: this.displayType === 'weekly' ? this.displayingWeek?.value?.startDate : this.displayingCustom?.value?.startDate,
          endDate: this.displayType === 'weekly' ? this.displayingWeek?.value?.endDate : this.displayingCustom?.value?.endDate
        }
      })
    }
  },

  computed: {
    months: () => months,

    yesterday() {
      return moment().subtract(1, 'day').format('YYYY-MM-DD')
    },

    today() {
      return this.getSimpleDay().slice(0, 10)
    },

    headersHaveMaxDate() {
      if (this.newHeaders.length === 0) return false

      const maxDate = this.today

      const maxDateInHeader = element => element.value === maxDate

      return this.newHeaders.some(maxDateInHeader)
    },

    displayItem() {
      if (this.displayType === 'monthly') return this.displayingMonth
      else if (this.displayType === 'weekly') return this.displayingWeek
      else return this.displayingCustom
    },

    maxDateWeekly() {
      return moment().subtract(6, 'days').format('YYYY-MM-DD')
    },

    newHeaders() {
      return [
        { text: this.translations.employee, value: 'employee' },
        ...this.dateHeaders,
        { text: this.translations.totalOrdinaryHours, value: 'totalOrdinaryHours' },
        { text: this.translations.totalExtraOrdinaryHours, value: 'totalExtraOrdinaryHours' },
        { text: this.translations.usedVan, value: 'usedVan' }
      ]
    },

    newItems() {
      if (!this.dailyReports) return []

      // get employees
      const employees = this.dailyReports.map(report => {
        return {
          employeeId: report.employee.id,
          employee: report.employee.name,
          totalOrdinaryHours: 0,
          totalExtraOrdinaryHours: 0,
          allowanceItems: [],
          usedVan: false
        }
      })

      // only unique
      let uniqEmployees = uniqBy(employees, 'employee')

      //sort alphabetical
      uniqEmployees.sort((a, b) => (a.employee > b.employee ? 1 : b.employee > a.employee ? -1 : 0))

      // make object with index to keep having to check everytime
      let nameIndexObj = uniqEmployees.reduce((obj, item, index) => {
        return {
          ...obj,
          [item['employee']]: index
        }
      }, {})

      // push with right format to the respective employee object
      this.dailyReports.forEach(item => {
        const { dateStr, employee, report } = item
        const { name } = employee
        const { extraOrdinaryHours, ordinaryHours } = report
        const slicedDate = dateStr.slice(0, 10)
        uniqEmployees[nameIndexObj[name]][slicedDate] = report
        // total Ordinary hours
        uniqEmployees[nameIndexObj[name]].totalOrdinaryHours = uniqEmployees[nameIndexObj[name]].totalOrdinaryHours + ordinaryHours
        // total Extra hours
        uniqEmployees[nameIndexObj[name]].totalExtraOrdinaryHours = uniqEmployees[nameIndexObj[name]].totalExtraOrdinaryHours + extraOrdinaryHours
        if (report.van) uniqEmployees[nameIndexObj[name]].usedVan = true
      })

      return uniqEmployees.map(employee => {
        const { allowanceItems, ...rest } = employee

        const types = {}

        allowanceItems.forEach(hr => {
          if (hr.allowance) {
            const totalName = `totalAllowance${hr.allowance}`
            if (!types[totalName]) {
              types[totalName] = hr.workHours
            } else {
              types[totalName] = types[totalName] + hr.workHours
            }
          }
        })

        return { ...rest, ...types }
      })
    },

    exportReports() {
      return getExportReports()
    }
  },

  watch: {
    employeeSearch() {
      this.employeeQueryVars.page = 1
    },

    employees() {
      this.employeeList = this.employees.content
      this.hasNextEmployees = this.employees.hasNext
    }
  }
}
</script>


<style>
div.time-table > div > table > thead > tr > th:first-child,
div.time-table > div > table > tbody > tr > td:first-child {
  position: sticky;
  left: 0 !important;
  background-color: #f4f4f4;
  z-index: 3;
}

div.time-table > div > table > tbody > tr > td:first-child {
  z-index: 2;
}

div.time-table > div > table > tbody > tr.v-data-table__empty-wrapper > td:first-child {
  background: none;
}

div.time-table > div > table > thead > tr > th:nth-last-child(-n + 3),
div.time-table > div > table > tbody > tr > td:nth-last-child(-n + 3) {
  position: sticky;
  right: 0;
  width: 120px;
  min-width: 120px;
  max-width: 120px;
  background-color: #f4f4f4;
  z-index: 3;
}

div.time-table > div > table > tbody > tr > td:nth-last-child(-n + 3) {
  z-index: 2;
}

div.time-table > div > table > thead > tr > th:nth-last-child(2),
div.time-table > div > table > tbody > tr > td:nth-last-child(2) {
  right: 120px;
  width: 120px;
  min-width: 120px;
  max-width: 120px;
}

div.time-table > div > table > thead > tr > th:nth-last-child(3),
div.time-table > div > table > tbody > tr > td:nth-last-child(3) {
  right: 240px;
  width: 120px;
  min-width: 120px;
  max-width: 120px;
}

div.time-table > div > table > thead > tr > th:not(:first-child) {
  text-align: center !important;
}

/* Scroll bar */
div.time-table > div.v-data-table__wrapper::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  border-radius: 5px;
}

/* Track */
div.time-table > div.v-data-table__wrapper::-webkit-scrollbar-track {
  background: #f6f8fa;
  border-radius: 5px;
}

/* Handle */
div.time-table > div.v-data-table__wrapper::-webkit-scrollbar-thumb {
  background: #d0cfcf;
  border-radius: 5px;
}

/* Handle on hover */
div.time-table > div.v-data-table__wrapper::-webkit-scrollbar-thumb:hover {
  background: #888;
  border-radius: 5px;
}

div.time-table > div > table {
  white-space: pre-line;
}

.tableSingleSlot {
  position: fixed;
  width: calc(100vw - 125px);
}
</style>
